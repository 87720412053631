
import router from '@/router';
import { generalStore } from '@/store';
import { ref, computed } from 'vue';
import useProduct from '@/modules/useProduct';
import { Product } from '@/models/Product';
import { useI18n } from 'vue-i18n';
import { WizzardStep } from '@/models/Enums';
import swal from 'sweetalert2';
export default {
    props: {
        productId: {
            type: String,
            required: true
        },
        fromWizzard: {
            type: String,
            default: 'false'
        }
    },
    async setup(props: any) {
        const { getProduct, saveProduct, saveProductRegions } = useProduct();
        if (!generalStore.state.regions.length) await generalStore.dispatch('loadAllRegions');

        const product = computed<Product>(() => generalStore.getters.product);
        if (!product.value || product.value.id != props.productId) await getProduct(props.productId);
        const allRegions = generalStore.state.regions;

        const selectedRegions = ref(product.value.productRegions.map(m => m.regionId));

        const save = async () => {
            if (product.value) {
                product.value.productRegions = [];

                selectedRegions.value?.forEach(id => {
                    product.value.productRegions.push({
                        id: '',
                        regionId: id,
                        productId: product.value.id
                    });
                });
                if (props.fromWizzard == 'true') {
                    product.value.wizzardStep = WizzardStep.None;
                }

                const apiResult = await saveProductRegions(product.value.id, selectedRegions.value, props.fromWizzard == 'true');
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
            }
            router.go(-1);
        };

        const close = () => {
            router.go(-1);
        };

        const clearAll = () => {
            selectedRegions.value = selectedRegions.value = [];
        };

        const search = ref('');
        const filteredRegions = computed(() => {
            return allRegions.filter(region => {
                return region.name.toLowerCase().indexOf(search.value.toLowerCase()) > -1;
            });
        });

        return {
            allRegions,
            selectedRegions,
            save,
            close,
            clearAll,
            search,
            filteredRegions
        };
    }
};
